<template>
  <div class="card" style="max-width:500px;margin:auto;margin-top:20px;">
    <div class="card-content">
      <b-field label="Email" type="is-grey">
        <b-input v-model="email" @keyup.native.enter="login" type="email"></b-input>
      </b-field>
      <b-field label="Password" type="is-grey">
        <b-input v-model="password" @keyup.native.enter="login" type="password" password-reveal></b-input>
      </b-field>
      <p class="has-text-danger has-text-centered mt-2" v-if="errorMessage">{{ errorMessage }}</p>
      <div class="has-text-centered mt-6">
        <b-button @click="login" type="is-success" label="Login" :loading="loading"/>
      </div>
    </div>
  </div>
</template>

<script>
import { auth } from '../firebase'
import { signInWithEmailAndPassword  } from 'firebase/auth'

export default {
  data () {
    return {
      auth,
      email: '',
      password: '',
      errorMessage: '',
      loading: false
    }
  },
  methods: {
    async login () {
      console.log('.')
      this.loading = true
      try {
        await signInWithEmailAndPassword(auth, this.email, this.password)
        this.$router.push({ name: 'Rooms Admin' })
      } catch (error) {
          this.errorMessage = 'Email oder Passwort ungültig'
      }
      this.loading = false
    }
  }
}
</script>

<style>
.has-text-primary {
  color: grey!important;
}
</style>
